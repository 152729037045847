import { userAxios } from '../../index'

const consultList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/consultationTypes/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const consultCreate = async data => {
  try {
    return await userAxios.post('insurance/consultationTypes', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const consultUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/consultationTypes/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const consultDelete = async id => {
  try {
    return await userAxios
      .delete(`insurance/consultationTypes/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const consultPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/consultationTypes/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const consultFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/consultationTypes/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const consultFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/consultationTypes/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  consultList,
  consultCreate,
  consultUpdate,
  consultDelete,
  consultPagination,
  consultFilter,
  consultFilterPagination,
}
