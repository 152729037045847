import { userAxios } from '../../index'

const visitList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/insuranceName/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitCreate = async data => {
  try {
    return await userAxios.post('insurance/insuranceName', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/insuranceName/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitDelete = async id => {
  try {
    return await userAxios.delete(`insurance/insuranceName/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/insuranceName/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/insuranceName/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const visitFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/insuranceName/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  visitList,
  visitCreate,
  visitUpdate,
  visitDelete,
  visitPagination,
  visitFilter,
  visitFilterPagination,
}
