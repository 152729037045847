<template>
  <b-modal
    id="add-new-membership-category-sidebar"
    :visible="isAddNewmembershipSidebarActive"
    bg-variant="white"
    title="Add new membership"
    size="xl"
    @hidden="closeSidebar"
    hide-footer
    @change="val => $emit('update:is-add-new-membership-category-sidebar-active', val)">
    <template #default="{ hide }">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm">
          <div>
            <div class="mt-4">
              <b-button
                class="mr-2"
                @click="toggleVisibilityMembership()"
                variant="primary"
                >Add membership</b-button
              >
              <b-button @click="toggleVisibilityCode()" variant="primary"
                >scan code</b-button
              >
            </div>

            <div class="mt-4" v-if="showContentCode">
              <validation-provider
                #default="validationContext"
                name="membership-parameters-lots"
                :rules="{
                  required: true,
                  totalRules: {
                    total: maxPrice,
                    totalPay: splitPay,
                  },
                }">
                <b-form-group
                  label="code"
                  label-for="code"
                  :invalid-feedback="validateTotal">
                  <b-form-input
                    id="code"
                    v-model="stateData.code"
                    type="password"
                    trim
                    placeholder="Enter code"
                    :class="{ animated: animatePassword }"
                    @click="animatePassword = true" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <div class="mt-4" v-if="showContent">
            <validation-provider
              #default="validationContext"
              name="membership-parameters"
              rules="required">
              <b-form-group
                label="Membership parameters"
                label-for="membership-parameters">
                <treeselect
                  id="membership-parameters"
                  v-model="stateData.parameter"
                  :async="true"
                  :load-options="searchParameters"
                  :defaultOptions="true"
                  :clearable="true"
                  :normalizer="normalizer"
                  valueFormat="object" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="cash"
              :rules="{
                required: true,
                totalRules: {
                  total: maxPrice,
                  totalPay: splitPay,
                },
              }">
              <b-form-group
                label="cash"
                label-for="cash"
                :invalid-feedback="validateTotal">
                <b-form-input
                  id="cash"
                  v-model="stateData.cash"
                  autofocus
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Type cash" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="cc"
              :rules="{
                required: true,
                totalRules: {
                  total: maxPrice,
                  totalPay: splitPay,
                },
              }">
              <b-form-group label="cc" label-for="cc" :invalid-feedback="validateTotal">
                <b-form-input
                  id="cc"
                  v-model="stateData.cc"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  placeholder="Type cc" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t('Add') }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button type="button" variant="outline-danger" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import formValidation from '@/core/utils/forms/form-validation'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import SpinnerLoading from '@/components/SpinnerLoading'

import { required } from '@validations'
import { ref, watch } from '@vue/composition-api'

import axiosCT from '@/core/services/apiInsurance/admin/consults/memberships'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddNewmembershipSidebarActive',
    event: 'update:is-add-new-membership-category-sidebar-active',
  },
  props: {
    isAddNewmembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
    patient: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      normalizer(node) {
        return {
          id: node.id,
          label: `${node.name}: ${node.price}`,
        }
      },
    }
  },
  computed: {
    splitPay() {
      return parseInt(this.stateData.cc) + parseInt(this.stateData.cash)
    },
    validateTotal() {
      extend('totalRules', {
        params: ['total', 'totalPay'],
        validate: (value, { total, totalPay }) => {
          if (totalPay != total) return false
          return true
        },
        message: (field, { total }) =>
          `El ${field} must be higher or greater to ${total}`,
      })
    },
  },
  setup(props, { emit }) {
    const showContent = ref(true)
    const showContentCode = ref(false)
    const animatePassword = ref(false)
    const facilities = ref([])
    const parameters = ref([
      { memberships_parameters_types_id: null, count: null, activated_at: null },
    ])
    const maxPrice = ref(0)
    const months = [
      { id: '1', label: 'January' },
      { id: '2', label: 'February' },
      { id: '3', label: 'March' },
      { id: '4', label: 'April' },
      { id: '5', label: 'May' },
      { id: '6', label: 'June' },
      { id: '7', label: 'July' },
      { id: '8', label: 'August' },
      { id: '9', label: 'September' },
      { id: '10', label: 'Octuber' },
      { id: '11', label: 'November' },
      { id: '12', label: 'December' },
    ]
    const facilityOptions = JSON.parse(atob(localStorage.getItem('setFacilityOptions')))
    const optionsStatus = [
      { name: 'Active', id: true },
      { name: 'Inactive', id: false },
    ]
    const stateDataTem = {
      cash: 0,
      cc: 0,
      memberships_parameters_id: null,
      status: true,
      code: null,
      patient_id: null,
    }
    const searchParameters = ({ action, searchQuery, callback }) => {
      if (action === 'ASYNC_SEARCH') {
        const datos = {
          name: searchQuery,
        }
        axiosCT.membershipParameterFilter(10, datos).then(({ data: { data } }) => {
          callback(null, data)
        })
      }
    }
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)))

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem))
    }

    watch(
      () => stateData.value,
      newVal => {
        if (!newVal.parameter) return (maxPrice.value = 0)

        maxPrice.value = newVal.parameter.price
      },
      { deep: true }
    )

    const closeSidebar = () => {
      emit('createmembership', { type: false })
    }
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    const loading = ref(false)

    const validateValues = value => {
      if (!value) return 'this field is required'

      if (value < 0) return "this field can't be less than zero"

      if (stateData.value.cash + stateData.value.cc != stateData.value.parameter.price)
        return "The sum between cash and cc can't be greater than the membership price"

      return true
    }

    const toggleVisibilityMembership = () => {
      showContent.value = true
      showContentCode.value = false
    }

    const toggleVisibilityCode = () => {
      showContent.value = false
      showContentCode.value = true
    }

    const onSubmit = () => {
      refFormObserver.value.validate().then(async success => {
        if (!success) return
        loading.value = true

        if (showContent.value) {
          stateData.value.memberships_parameters_id = stateData.value.parameter.id
          stateData.value.patient_id = props.patient.id
          stateData.value.seller_id = JSON.parse(localStorage.getItem('userData')).id
          const datos = {
            ...stateData.value,
          }
          axiosCT
            .membershipsCreate(datos)
            .then(({ registro, message }) => {
              loading.value = false
              resetuserData()
              emit('createmembership', { type: 'success', message })
            })
            .catch(() => {
              loading.value = false
              emit('createmembership', { type: 'danger' })
            })
        }

        if (showContentCode.value) {
          const data = {
            code: stateData.value.code,
            patient_id: props.patient.id,
          }
          loading.value = false

          axiosCT
            .membershipsAddPatienLots(data)
            .then(({ status, message }) => {
              loading.value = false
              resetuserData()
              if (status == 'error') {
                return emit('createmembership', { type: 'danger', message })
              } else {
                return emit('createmembership', { type: 'success', message })
              }
            })
            .catch(() => {
              loading.value = false
              emit('createmembership', { type: 'danger' })
            })
        }
      })
    }
    return {
      loading,
      stateData,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      searchParameters,
      closeSidebar,
      facilities,
      months,
      facilityOptions,
      parameters,
      maxPrice,
      validateValues,
      toggleVisibilityMembership,
      toggleVisibilityCode,
      animatePassword,
      showContent,
      showContentCode,
    }
  },
}
</script>

<style>
.animated {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
