<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("tableHeader.name") }}</label>
          <b-form-input autofocus v-model.trim="filters.name" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.City") }}</label>
          <treeselect v-model="filters.cities_id" :async="true" :load-options="searchState" :normalizer="normalizer"
            @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>DOB</label>
          <flat-pickr v-model="filters.date_birth" :config="config" class="form-control" placeholder="MM-DD-YYYY" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Date</label>
          <flat-pickr v-model="filters.create" :config="config" class="form-control" placeholder="MM-DD-YYYY" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Email</label>
          <b-form-input v-model.trim="filters.email" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Phone") }}</label>
          <b-form-input v-model.number="filters.phone" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Gender") }}</label>
          <treeselect v-model="filters.gender" :options="genderOptions" :normalizer="normalizer"
            @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Status") }}</label>
          <treeselect v-model="filters.status" :options="statusOptions" :normalizer="normalizer"
            @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Facility</label>
          <treeselect v-model="filters.facilities_id" :async="true" :load-options="searchFacility" :defaultOptions="true"
            :normalizer="normalizer" @keyup.enter="searchFilter" multiple />
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
          <b-button variant="success" @click="excel" class="mr-1" v-if="$can('index', 'administration')">Excel</b-button>
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from "bootstrap-vue";

import axiosC from "@/core/services/apiInsurance/admin/cities";
import axiosF from "@/core/services/apiInsurance/admin/facilities";

import SpinnerLoading from "@/components/SpinnerLoading";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    flatPickr,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setTimeoutBuscador: "",
      stateOptions: [],
      filters: {
        name: null,
        cities_id: null,
        date_birth: null,
        create: null, // Nuevo campo añadido
        email: null,
        phone: null,
        gender: null,
        status: null,
      },
      genderOptions: [
        { name: this.$t("Male"), id: "male" },
        { name: "Female", id: "female" },
      ],
      statusOptions: [
        { name: "Active", id: "true" },
        { name: "Inactive", id: "false" },
      ],
      config: {
        dateFormat: "m-d-Y", 
        allowInput: true,
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  methods: {
    excel() {
      // Implementación del método Excel
    },
    searchFacility({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        const datos = {
          name: searchQuery,
        };
        axiosF.facilityFilter(10, datos).then(({ data }) => {
          callback(null, data);
        });
      }
    },
    searchFilter() {
      const data = this.filters;
      console.log("🚀 ~ searchFilter ~ data:", data)
      this.$emit("searchFilter", JSON.parse(JSON.stringify(data)));
      let {
        name,
        cities_id,
        date_birth,
        create,
        email,
        phone,
        gender,
        status,
      } = this.filters;
      if (
        cities_id == null &&
        name == null &&
        date_birth == null &&
        create == null &&
        email == null &&
        phone == null &&
        gender == null &&
        status == null
      ) {
        this.$emit("filterPage", false);
      } else {
        this.$emit("filterPage", true);
      }
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
    searchState({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: searchQuery,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosC.citiesFilterFilters(datos).then(({ data }) => {
            callback(null, data);
          });
        }, 350);
      }
    }
  },
};
</script>